<template>
  <div class="sing-page-container"  :class="{ 'layout-border': !isReviewPage }">
    <SingPage 
      :singList = "singList" 
      :songInfo = "songInfo" 
      :timeStampList = "timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-5.mp3"),
      },
      timeStampList: [7.8, 9.8, 11.7, 13.6,25.5,27.7,29.4,31.7,35.0],
      singList:{
        lyricsArr: [
        {
          id: 1,
          spelling: "Yī, èr, sān, sì, wǔ.",
          chineseWords: "一、二、三、四、五，",
        },
        {
          id: 2,
          spelling: "Lǎoshī nǐ hǎo! Lǎoshī hǎo!",
          chineseWords: "老师你好！老师好！",
        },
        {
          id: 3,
          spelling: "Liù, qī, bā, jiǔ, shí,",
          chineseWords: "六、七、八、九、十，",
        },
        {
          id: 4,
          spelling: "Lǎoshī! Lǎoshī! Zàijiàn!",
          chineseWords: "老师！老师！再见！",
        },
         {
          id: 5,
          spelling: "Yī, èr, sān, sì, wǔ, ",
          chineseWords: "一、二、三、四、五，",
        },
        {
          id: 6,
          spelling: "Liù, qī, bā, jiǔ, shí.",
          chineseWords: "六、七、八、九、十。",
        },
        {
          id: 7,
          spelling: "Lǎoshī nǐ hǎo! Lǎoshī hǎo!",
          chineseWords: "老师你好！老师好！",
        },
       
        {
          id: 8,
          spelling: "Lǎoshī! Lǎoshī! Zàijiàn!",
          chineseWords: "老师！老师！再见！",
        },
      ],
      },
    };
  },
};
</script>
